import React from "react";
import styled from "styled-components";
import Button, { ButtonTypes } from "../atoms/button";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";

const LandingContainer = styled.div`
   position: relative;
   height: 100vh;
   display: flex;
   justify-content: center;
   align-items: center;
   overflow: hidden;
`;

const HomeContainer = styled.div`
   position: relative;
   z-index: 2;
   width: 100%;
   max-width: var(--screen-max-width);
   margin: var(--screen-margin);
   height: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   gap: 24px;
`;

const TextContainer = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;
   gap: 16px;
   color: var(--text-inverted);
   
   .subheading {
      max-width: 640px;
   }

   h1 {
      max-width: 766px;
   }
`;

const ButtonsContainer = styled.div`
   display: flex;
   gap: 16px;

   @media only screen and (max-width: 600px) {
      flex-direction: column;

      button,
      a {
         width: 100%;
         box-sizing: border-box;
      }
   }
`;

const LandingContent = () => {
   const delayFactor = (index) => 100 * index;

   // Query the background image
   const data = useStaticQuery(graphql`
      query {
         bgImage: file(relativePath: { eq: "landingImg.png" }) {
            childImageSharp {
               gatsbyImageData(layout: FULL_WIDTH, quality: 100)
            }
         }
      }
   `);
   

   const bgImage = getImage(data.bgImage);

   return (
      <LandingContainer>
         {/* Background Image */}
         <GatsbyImage
            image={bgImage}
            alt="Landing Background"
            style={{
               position: "absolute",
               width: "100%",
               height: "100%",
            }}
         />

         {/* Content */}
         <HomeContainer>
            <TextContainer>
               <h1 data-aos="fade-up" data-aos-delay={delayFactor(1)}>
                  Quality IT professionals at prices that make sense
               </h1>
               <p data-aos="fade-up" data-aos-delay={delayFactor(2)} className="subheading">
                  Through our years of staffing experience and our understanding
                  of technology, we are able to provide you with Top-tier
                  European talent at competitive rates.
               </p>
            </TextContainer>
            <ButtonsContainer>
               <Button
                  content="Contact us"
                  type={ButtonTypes.PRIMARY}
                  url="/contact-us"
                  data-aos="fade-up"
                  data-aos-delay={delayFactor(3)}
               />
               <Button
                  content="Calculate for free"
                  type={ButtonTypes.SECONDARY}
                  url="/hirewise"
                  data-aos="fade-up"
                  data-aos-delay={delayFactor(4)}
               />
            </ButtonsContainer>
         </HomeContainer>
      </LandingContainer>
   );
};

export default LandingContent;
